import React, { useState, useEffect } from "react";
import { Table, Spin, Modal } from "antd";
import { FaUserCircle } from "react-icons/fa";
import {
    FaHospitalUser,
    FaHandHoldingMedical,
    FaWheelchair,
} from "react-icons/fa6";
import { TbReportMedical } from "react-icons/tb";
import { BiLogOutCircle } from "react-icons/bi";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    useGetAppointmentMutation,
    useGetPatientStatisticsMutation,
} from "../../redux/slices/patientSlice";
import { useGetProductMutation } from "../../redux/slices/productSlice";
import { FcShop } from "react-icons/fc";
import { useSelector } from "react-redux";

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector(
        (state) => state?.persistedReducer?.user?.user
    );
    const [getProduct, { isLoading: isProductLoading }] = useGetProductMutation();
    const [getAppointment, { isLoading: isAppointmentLoading }] =
        useGetAppointmentMutation();
    const [getPatientStastic, { isLoading: isPatientStatisticsLoading }] =
        useGetPatientStatisticsMutation();
    const [upcomingPatient, setupcomingPatient] = useState([]);
    const [upcomingPage, setUpcomingPage] = useState(1);
    const [upcomingPatientTotal, setupcomingPatientTotal] = useState(0);
    const [patientStastics, setPatientStastics] = useState();
    const [expiredProducts, setExpiredProducts] = useState();
    const [expiredProductsTotal, setExpiredProductsTotal] = useState();
    const [expiredPage, setExpiredPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [logoutClicked, setLogoutClicked] = useState(false);
    const [injectible, setInjectible] = useState([]);
    const [injectiblePage, setInjectiblePage] = useState(1);
    const [totalInjectiblePage, setTotalInjectiblePage] = useState(0);
    const [medications, setMedications] = useState([]);
    const [medicationsPage, setMedicationsPage] = useState(1);
    const [totalMedicationsPage, setTotalMedicationsPage] = useState(0);

    const getPatientStastics = async () => {
        getPatientStastic()
            .then((response) => {
                setPatientStastics(response?.data);
            })
            .catch((err) => {
                console.error({ err });
            });
    };

    useEffect(() => {
        const hasSeenModal = sessionStorage.getItem("hasSeenModal");

        if (!hasSeenModal) {
            setIsModalOpen(true);
            sessionStorage.setItem("hasSeenModal", "true");
        }
        getPatientStastics();
    }, []);

    const getExpiredProducts = async (value) => {
        const res = await getProduct(value);
        setExpiredProducts(res?.data?.data);
        setExpiredProductsTotal(res?.data?.total);
    };
    const getUpcomingProspects = async (value) => {
        const res = await getAppointment(value);
        setupcomingPatient(res?.data?.data);
        setupcomingPatientTotal(res?.data?.total);
    };

    const getInjectibleProducts = async (value) => {
        const res = await getProduct(value);
        setInjectible(res?.data?.data);
        setTotalInjectiblePage(res?.data?.total);
    };

    const getMedicationProducts = async (value) => {
        const res = await getProduct(value);
        setMedications(res?.data?.data);
        setTotalMedicationsPage(res?.data?.total);
    };

    useEffect(() => {
        const query = `?per_page=5&page=${expiredPage}&expired=1`;
        getExpiredProducts(query);
    }, [expiredPage, getProduct]);
    useEffect(() => {
        const query = `?per_page=5&page=${upcomingPage}`;
        getUpcomingProspects(query);
    }, [upcomingPage]);

    useEffect(() => {
        const query = `?per_page=5&page=${injectiblePage}&low_stock=true&category_id=1`;
        getInjectibleProducts(query);
    }, [injectiblePage]);

    useEffect(() => {
        const query = `?per_page=5&page=${medicationsPage}&low_stock=true&category_id=3`;
        getMedicationProducts(query);
    }, [medicationsPage]);


    const handlePatientDetails = (key) => {
        navigate("/patientappointment", {
            state: { patientId: key },
        });
    }

    const handleProductDetails = (id) => {
        navigate("/product-details", {
            state: { productId: id },
        });
    };

    const LowStockMedicationsColumns = [
        {
            title: "Product Name",
            dataIndex: "product_name",
            key: "product_name",
            render: (text, record) => (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={record.image}
                            alt={text}
                            style={{
                                width: 50,
                                height: 50,
                                marginRight: 20,
                                borderRadius: "5px",
                            }}
                        />
                        <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                            {record?.item_name}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (text, record) => (
                <>
                    <span>{record?.category?.name}</span>
                </>
            ),
        },
        {
            title: "Inventory Count",
            dataIndex: "inventory_count",
            key: "inventory_count",
            render: (text, record) => (
                <>
                    <span>{record?.quantity}</span>
                </>
            ),
        },

    ];

    const LowStockInjectiblesColumns = [
        {
            title: "Product Name",
            dataIndex: "product_name",
            key: "product_name",
            render: (text, record) => (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={record.image}
                            alt={text}
                            style={{
                                width: 50,
                                height: 50,
                                marginRight: 20,
                                borderRadius: "5px",
                            }}
                        />
                        <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                            {record?.item_name}
                        </span>
                    </div>
                </>

            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (text, record) => (
                <>
                    <span>{record?.category?.name}</span>
                </>
            ),
        },
        {
            title: "Inventory Count",
            dataIndex: "inventory_count",
            key: "inventory_count",
            render: (text, record) => (
                <>
                    <span>{record?.quantity}</span>
                </>
            ),
        },
    ];

    const UpcomingProspects = [
        {
            title: "Name",
            dataIndex: "customerName",
            key: "customerName",
            render: (text, record) => (
                <>
                    <span>{record?.patient_name}</span>
                </>
            ),
        },
        {
            title: "Email",
            dataIndex: "customerEmail",
            key: "customerEmail",
            render: (text, record) => (
                <>
                    <span>{record?.patient?.email}</span>
                </>
            ),
        },
    ];

    const expiredMedicineColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={record.image}
                        alt={text}
                        style={{
                            width: 50,
                            height: 50,
                            marginRight: 20,
                            borderRadius: "5px",
                        }}
                    />
                    <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                        {record?.item_name}
                    </span>
                </div>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (text, record) => (
                <>
                    <span>
                        {record?.category?.name !== "" ? record?.category?.name : "N/A"}
                    </span>
                </>
            ),
        },
        {
            title: "Company Name",
            dataIndex: "company",
            key: "company",
            render: (text, record) => (
                <>
                    <span>
                        {record?.company_name !== "" ? record?.company_name : "N/A"}
                    </span>
                </>
            ),
        },
    ];

    const handleLogout = () => {
        dispatch(logout());
        sessionStorage.clear();
        navigate("/login");
    };

    const handleLogoutClick = () => {

        setIsModalOpen(true);
        setLogoutClicked(true);
    };

    const handleProceed = () => {
        setIsModalOpen(false);
        navigate('/inventory')
    };

    const handleSkip = () => {
        if (logoutClicked) {
            setLogoutClicked(false);
            handleLogout();

        } else {
            setIsModalOpen(false);
            setLogoutClicked(false);
        }
    };

    const handleCloseModal = () =>{
        setIsModalOpen(false);
        setLogoutClicked(false);
    }

    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleBar d-flex justify-content-between">
                            <h4>Dashboard</h4>
                            <div className="buttonWrap">
                                <button
                                    type="button"
                                    className="btn backBtn"
                                    onClick={handleLogoutClick}
                                >
                                    <BiLogOutCircle /> SIGN OUT
                                </button>
                                {userData?.profile_image?.includes("https")? (
                                     <img style={{width:"35px",height:"35px", borderRadius:"50%",objectFit:"cover"}} src={userData?.profile_image}/>
                                ):(<FaUserCircle
                                    fontSize={35}
                                    onClick={() => navigate("/my-account")}
                                />)}
                               
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-50 mb-4">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className="leftSection">
                                <div className="dashIcon">
                                    <FaHospitalUser />
                                </div>
                                <h5>Total Patients</h5>
                            </div>

                            <div className={isPatientStatisticsLoading ? "" : "dashboardContent"}>
                                <span>
                                    {isPatientStatisticsLoading ? <Spin size="medium"/> : patientStastics?.total_patients}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-50 mb-4">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className="leftSection">
                                <div className="dashIcon">
                                    <FaWheelchair />
                                </div>
                                <h5>Patients Visited in a week</h5>
                            </div>
                            <div className={isPatientStatisticsLoading ? "" : "dashboardContent"}>
                                <span>
                                    {isPatientStatisticsLoading ? (
                                        <Spin size="medium"/>
                                    ) : (
                                        patientStastics?.patient_visits_last_week
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-50 mb-4">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className="leftSection">
                                <div className="dashIcon">
                                    <FaHandHoldingMedical />
                                </div>
                                <h5>Total Patients in 90 Days</h5>
                            </div>
                            <div className={isPatientStatisticsLoading ? "" : "dashboardContent"}>
                                <span>
                                    {isPatientStatisticsLoading ? (
                                        <Spin size="medium"/>
                                    ) : (
                                        patientStastics?.total_patients_last_90_days
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-50 mb-4">
                        <div className="dashboardItem" onClick={() => console.log()}>
                            <div className="leftSection">
                                <div className="dashIcon">
                                    <TbReportMedical />
                                </div>
                                <h5>Total Upcoming Prospects</h5>
                            </div>
                            <div className={isAppointmentLoading ? "" : "dashboardContent"}>
                                <span>
                                    {isAppointmentLoading ? <Spin size="medium"/> : upcomingPatientTotal}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="card dashboardCard">
                            <div className="card-header">
                                <h4 className="card-title">Low Stocks Medications</h4>
                            </div>
                            <div className="card-body">
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={medications}
                                        columns={LowStockMedicationsColumns}
                                        loading={isProductLoading}
                                        pagination={{
                                            pageSize: 5,
                                            showSizeChanger: false,
                                            total: totalMedicationsPage,
                                            onChange: (page) => setMedicationsPage(page),
                                        }}
                                        onRow={(record) => ({
                                            onClick: () => handleProductDetails(record?.id),
                                        })}
                                        bordered
                                        scroll={{ x: 'auto' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card dashboardCard">
                            <div className="card-header">
                                <h4 className="card-title">Low Stocks Injectables</h4>
                            </div>
                            <div className="card-body">
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={injectible}
                                        columns={LowStockInjectiblesColumns}
                                        loading={isProductLoading}
                                        pagination={{
                                            pageSize: 5,
                                            showSizeChanger: false,
                                            total: totalInjectiblePage,
                                            onChange: (page) => setInjectiblePage(page),
                                        }}
                                        onRow={(record) => ({
                                            onClick: () => handleProductDetails(record?.id),
                                        })}
                                        bordered
                                        scroll={{ x: 'auto' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="card dashboardCard">
                            <div className="card-header">
                                <h4 className="card-title">Upcoming Prospects</h4>
                            </div>
                            <div className="card-body">
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={upcomingPatient}
                                        columns={UpcomingProspects}
                                        loading={isAppointmentLoading}
                                        pagination={{
                                            pageSize: 5,
                                            showSizeChanger: false,
                                            total: upcomingPatientTotal,
                                            onChange: (page) => setUpcomingPage(page),
                                        }}
                                        scroll={{ x: 'auto' }}
                                        onRow={(record) => ({
                                            onClick: () => handlePatientDetails(record?.patient?.id),
                                        })}
                                        bordered
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card dashboardCard">
                            <div className="card-header">
                                <h4 className="card-title">Expired Medicine</h4>
                            </div>
                            <div className="card-body">
                                <div className="tableWrapper">
                                    <Table
                                        className="dashboardTable productTable"
                                        dataSource={expiredProducts}
                                        columns={expiredMedicineColumns}
                                        loading={isProductLoading}
                                        pagination={{
                                            pageSize: 5,
                                            showSizeChanger: false,
                                            total: expiredProductsTotal,
                                            onChange: (page) => setExpiredPage(page),
                                        }}
                                        scroll={{ x: 'auto' }}
                                        onRow={(record) => ({
                                            onClick: () => handleProductDetails(record?.id),
                                        })}
                                        bordered
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title="Inventory Update Reminder"
                open={isModalOpen}
                onCancel={handleCloseModal}
                footer={false}
                className="inventoryModal"
            >
                <div className="modalBody">
                    <div className="inventoryIcon">
                        <FcShop />
                    </div>
                    <p>Please update your inventory</p>
                    <div className="modalFooter">
                        <button type="button" className="btn skipBtn" onClick={() => handleSkip()}>{logoutClicked ? "Logout" : "Skip"}</button>
                        <button type="button" className="btn proceedBtn" onClick={() => handleProceed()}>Proceed</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Dashboard;
