import React, { useState, useEffect } from "react";
import { Select, Input, Table, Button, Modal, Form, Col, Row, Spin, Checkbox, DatePicker } from "antd";
import { FaFilter } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import moment from 'moment';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdRefresh } from "react-icons/md";
import {
	useGetOrderMutation,
	useGenerateOrderMutation,
	useGetCategoriesMutation,
	useGetProductsByCategoryIdMutation
} from "../../redux/slices/productSlice";
import {
	useGetPatientsWithAppointmentsMutation,
	useGetFilteredpatientMutation
} from "../../redux/slices/patientSlice";
import {
	useGetSettingDetailsMutation
} from "../../redux/slices/userSlice";
import Swal from "sweetalert2";
import Shipping from "./shipping"
import ShippingDetails from "./ShippindDataModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const OrderingForMedication = () => {
	const { TextArea } = Input;
	const navigate = useNavigate();
	const userData = useSelector(
		(state) => state?.persistedReducer?.user?.user
	);
	const [showFilterSection, setShowFilterSection] = useState(false);
	const [getPatientsWithAppointments, { isLoading: isLoadingPatient }] =
		useGetPatientsWithAppointmentsMutation();
	const [generateOrder] = useGenerateOrderMutation();
	const [getOrder, { isLoading: isLoadinggetOrder }] = useGetOrderMutation();
	const [getCategories, { isLoading: isCategoriesLoading }] = useGetCategoriesMutation();
	const [getProductsByCategoryId, { isLoading: isProductLoading }] = useGetProductsByCategoryIdMutation();
	const [getFilteredpatient, { isLoading: isFilterPatientLoading }] = useGetFilteredpatientMutation();
	const [getSettingDetails,] = useGetSettingDetailsMutation();
	const [orderData, setorderData] = useState([]);
	const [orderTotal, setOrderTotal] = useState(0);
	const [orderPerPage, setOrderPerPage] = useState(10);
	const [orderPage, setOrderPage] = useState(1);
	const [orderSearch, setOrderSearch] = useState('');
	const [selectedOrderData, setSelectedOrderData] = useState();
	const [last_visit_days, setLastVisit] = useState(null);
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [selectedProductId, setSelectedProductId] = useState(null);
	const [patientTotal, setPatientTotal] = useState(0);
	const [filteredPatientTotal, setFilteresPatientTotal] = useState(0);
	const [patientPerPage, setPatientPerPage] = useState(10);
	const [patientPage, setPatientPage] = useState(1);
	const [filteredPatientData, setFilteredPatientData] = useState([]);
	const [daysFilteredPatientData, setDaysFilteredPatientData] = useState([]);
	const [daysFilterPatientTotal, setDaysFilterPatientTotal] = useState(0);
	const [modal1Open, setModal1Open] = useState(false);
	const [shippingModal, setShippingModal] = useState(false);
	const [shippingDetailsModal, setShippingDetailsModal] = useState(false);
	const [quantity, setQuantity] = useState(0);
	const [patientDetails, setPatientDetails] = useState();
	const [categories, setCategories] = useState([]);
	const [products, setProducts] = useState([]);
	const [selectedShippingData, setSelectedShippingData] = useState();
	const [prescriberLoader, setPrescriberLoader] = useState(false);
	const [patientSearch, setPatientSearch] = useState('');
	const [settingDetails, setSettingDetails] = useState();
	const [orderType, setOrderType] = useState("single_order")
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [lastOrderday, setLastOrderday] = useState(null);

	useEffect(() => {
		getSettingDetails().then((response) => {
			setSettingDetails(response?.data)
		}).catch((error) => {
			console.error({ error })
		})
	}, [])

	// useEffect(() => {
	//     let query = `?per_page${orderPerPage}&page=${orderPage}`
	//     if (orderSearch !== '') {
	//         query += `&search=${orderSearch}`
	//     }
	//     getOrderData(query)
	// }, [orderPerPage, orderPage, orderSearch]);

	const getPatientData = (value) => {
		getPatientsWithAppointments(value)
			.then((response) => {
				setFilteredPatientData(response?.data?.data);
				setPatientTotal(response?.data?.total);
				setFilteresPatientTotal(response?.data?.total);
			})
			.catch((err) => {
				console.error("err", err);
			});
	};


	const getOrderData = (query) => {
		getOrder(query)
			.then((res) => {
				setorderData(res?.data?.data?.data);
				setOrderTotal(res?.data?.data?.total);
			})
			.catch((err) => {
				console.error("err", err);
			});
	}

	const getFilteredPatients = (value) => {
		getFilteredpatient(value).then((res) => {
			setDaysFilteredPatientData(res?.data?.data);
			setDaysFilterPatientTotal(res?.data?.total);
		}).catch((error) => {
			console.error("Error fetching filtered patients:", error);
		});
	}

	useEffect(() => {
		if (daysFilteredPatientData?.length === 0) {
			let query = `?per_page=${patientPerPage}&page=${patientPage}`;
			if (patientSearch !== '') {
				query += `&search=${patientSearch}`;
			}
			getPatientData(query);
		}else if (daysFilteredPatientData?.length !== 0) {
			let query = `?days=${last_visit_days}&per_page=${patientPerPage}&page=${patientPage}`;
			if (patientSearch !== '') {
				query += `&search=${patientSearch}`
			}
			if(lastOrderday){
				query += `&last_order_date=${lastOrderday}`
			}
			getFilteredPatients(query)
		}
	}, [patientPerPage, patientPage, patientSearch]);

	const handledayChange = (day) => {
		setLastVisit(day);
		setLastOrderday(null)
		setPatientPerPage(10)
		setPatientPage(1)
		let query = `?days=${day}&per_page=${10}&page=${1}`
		if (patientSearch !== '') {
			query += `&search=${patientSearch}`
		}
		getFilteredPatients(query)
	};
	const handleLastOrderDay = (day) => {
		setLastOrderday(day);
		let query = `?days=${last_visit_days}&per_page=${10}&page=${1}&last_order_date=${day}`
		if (patientSearch !== '') {
			query += `&search=${patientSearch}`
		}
		getFilteredPatients(query)
	};

	const handlePatientSearch = (event) => {
		setPatientPage(1)
		setPatientPerPage(10)
		setPatientSearch(event?.target?.value)
	};

	const handleOrderSearch = (event) => {
		setOrderPage(1)
		setOrderPerPage(10)
		setOrderSearch(event?.target?.value)
	};

	const onPageSizeChange = (current, size) => {
		setPatientPerPage(size);
		setPatientPage(1);
	};

	// const onOrderPageSizeChange = (current, size) => {
	//     setOrderPerPage(size);
	//     setOrderPage(1);
	// };

	const generatePageSizeOptions = (total) => {
		const options = [];
		const maxPageSize = Math.min(total, 100);

		for (let i = 5; i <= maxPageSize; i += 5) {
			options.push(i);
		}
		if (total > 5 && !options.includes(10)) {
			options.push(10);
		}

		if (total > 10 && !options.includes(15)) {
			options.push(15);
		}
		return options.sort((a, b) => a - b);
	};

	const handleOrder = (value) => {
		if (selectedProductId) {
			if (value?.email === "") {
				Swal.fire({
					title: "Email Required",
					text: "We're unable to create your order because Strive requires a valid email address.",
					icon: "info",
					confirmButtonText: "OK",
				});
			} else {
				setModal1Open(true);
				setPatientDetails(value);
			}

		} else {
			Swal.fire({
				title: "Product",
				text: "Please select product.",
				icon: "info",
				confirmButtonText: "OK",
			});
		}
	};

	const handleEdit = (key) => {
		navigate("/patientappointment", {
			state: { patientId: key },
		});
	}

	const pageSizeOptions = generatePageSizeOptions(daysFilteredPatientData?.length > 0 ? daysFilteredPatientData?.length : patientTotal);
	// const pageSizeOptionsOrder = generatePageSizeOptions(orderTotal);

	const dataSource = (daysFilteredPatientData?.length > 0 ? daysFilteredPatientData : filteredPatientData)?.map((record, i) => {
		
		const patient = record?.patient || {}; // Use `patient` object once to avoid repetition
		const address = record?.address || patient?.address || "N/A";
		const phone = record?.phone || patient?.phone || "N/A";
		const email = record?.email || patient?.email || "N/A";
		const city = record?.city || patient?.city || "N/A";
		const state = record?.state || patient?.state || "N/A";
		const zip = record?.zip || patient?.zip || "N/A";
		const last_order_date = record?.last_order_date || patient?.last_order_date || "N/A";
		const firstName = record?.first_name || record?.patient?.first_name;
		const lastName = record?.last_name || record?.patient?.last_name;

		const isDaysFiltered = daysFilteredPatientData?.length > 0;

		return {
			key: record?.id,
			name: <span>{firstName} {lastName}</span>,
			email: <span className="text-center">{email}</span>,
			phone: <span className="text-center">{phone}</span>,
			address: <span>{address !== "N/A" ? address : phone}</span>,
			city: <span>{city}</span>,
			state: <span>{state}</span>,
			zip: <span>{zip}</span>,
			last_order_date:<span>{last_order_date}</span>,
			edit: isDaysFiltered || record?.appointments?.length > 0 ? (
				<IoMdInformationCircleOutline
					style={{ cursor: "pointer", color: '#b4a099' }}
					onClick={() => handleEdit(record?.patient?.id || record?.id)} // Ensure it uses correct ID
				/>
			) : (
				<span className="text-center">N/A</span>
			),
			create_order: (
				<Button className="craete-order-button" onClick={() => handleOrder(record)}>
					Create Order
				</Button>
			),
		};
	});

	const SaveSelectedKey = (value) => {
		setSelectedRowKeys((prevSelectedRowKeys) => {
			// Check if the value is already selected
			if (prevSelectedRowKeys.includes(value)) {
				// Remove the value if it exists
				return prevSelectedRowKeys.filter((key) => key !== value);
			} else {
				// Add the value if it doesn't exist
				return [...prevSelectedRowKeys, value];
			}
		});
	};


	const checkBoxCheckFunction = (key) => {
		let findID = selectedRowKeys?.find((item) => item === key);
		if (findID !== undefined && findID !== null) {
			return true
		} else {
			return false;
		}
	}
	const tableColumns = [

		...(orderType === 'bulk_order' ? [
			{
				key: '',
				render: ((record) => (
					<>
						<Checkbox onChange={() => SaveSelectedKey(record.key)} checked={checkBoxCheckFunction(record.key)} />
					</>
				)),
			},
		] : []),
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Phone",
			dataIndex: "phone",
			key: "phone",

		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address",
		},
		{
			title: "City",
			dataIndex: "city",
			key: "city",
		},
		{
			title: "State",
			dataIndex: "state",
			key: "state",
		},
		{
			title: "Zip",
			dataIndex: "zip",
			key: "zip",
		},
		{
			title: "Last Order Date",
			dataIndex: "last_order_date",
			key: "last_order_date",
		},
		{
			title: "Info",
			dataIndex: "edit",
			key: "edit",
		},
		...(orderType !== 'bulk_order' ? [
			{
				title: "Create Order",
				dataIndex: "create_order",
				key: "create_order",
			},
		] : []),
	];


	const onFinish = (value) => {
		setPrescriberLoader(true)
		let messageId = Math.floor(100000 + Math.random() * 900000);
		var currentdate = new Date();
		var datetime =
			"Last Sync: " +
			currentdate.getDate() +
			"/" +
			(currentdate.getMonth() + 1) +
			"/" +
			currentdate.getFullYear() +
			" @ " +
			currentdate.getHours() +
			":" +
			currentdate.getMinutes() +
			":" +
			currentdate.getSeconds();
		const formData = {
			category: { category_id: selectedCategoryId },
			product: { product_id: selectedProductId?.value },
			email: { email: patientDetails?.email ? patientDetails?.email : patientDetails?.patient?.email },
			message: { id: messageId, sentTime: datetime },
			patient_id:patientDetails?.id,
			user_id: userData?.id,
			order: {
				practice: { id: 1094510 },
				customer_id:patientDetails?.customer_id,
				patient: {
					lastName: patientDetails?.last_name ? patientDetails?.last_name : patientDetails?.patient?.last_name,
					firstName: patientDetails?.first_name ? patientDetails?.first_name : patientDetails?.patient?.first_name,
					gender: "m",
					dateOfBirth: "1980-05-12",
					address1: patientDetails?.address ? patientDetails?.address : patientDetails?.patient?.address,
					city: patientDetails?.city ? patientDetails?.city : patientDetails?.patient?.city,
					state: "AZ",
					zip: patientDetails?.zip ? patientDetails?.zip : patientDetails?.patient?.zip,
					phoneHome: patientDetails?.phone ? patientDetails?.phone : patientDetails?.patient?.phone,
				},
				rxs: [
					{
						rxType: "new",
						drugName: selectedProductId?.item_name,
						drugStrength: value?.drugStrength, 
						drugForm: value?.drugForm, 
						quantity: value?.quantity,
						quantityUnits: value?.quantityUnits,
						directions: value?.directions,
						daysSupply: value?.daysSupply, 
						clinicalDifferenceStatement : value?.clinicalDifferenceStatement
					  }
				],
			},
		};


		generateOrder(formData).then((response) => {

			setPatientDetails()
			setModal1Open(false)
			if (userData?.user_role === "nurse") {
				Swal.fire({
					title: "Order sent for approval",
					text: `${response?.data?.message}`,
					icon: "success",
					confirmButtonText: "OK",
				});
			} else {
				if(response.error){
					Swal.fire({
						title: response.error.data.message,
						icon: "error",
						confirmButtonText: 'OK'
					});
					return;
				}else{
					Swal.fire({
						title: "Order Generated",
						text: "Your order has been successfully generated",
						icon: "success",
						confirmButtonText: "OK",
					});
				}
				

			}
			setPatientPage(1)
			setPatientPerPage(10)
			setOrderPage(1)
			setOrderPerPage(10)
			setPatientSearch('')
			setOrderSearch('')
			// let query = `?per_page=${10}&page=${1}`
			// getOrderData(query)
			setPrescriberLoader(false)
		}).catch((error) => {
			setPrescriberLoader(false)

			// getOrderData()
			setPatientDetails()
			setModal1Open(false)
			Swal.fire({
				title: "Order Fialed",
				text: "Your order has been failed.",
				icon: "error",
				confirmButtonText: "OK",
			});
		})

	};
	const onFinishFailed = (error) => {
		console.error({ error });
	};

	const options = [
		{
			value: "5",
			label: "5",
		},
		{
			value: "10",
			label: "10",
		},
		{
			value: "15",
			label: "15",
		},
		{
			value: "20",
			label: "20",
		},
		{
			value: "25",
			label: "25",
		},
		{
			value: "30",
			label: "30",
		},
	];

	const onQuantityChange = (value) => {
		setQuantity(value);
	};

	const getCategoriesList = () => {
		getCategories().then((response) => {
			setCategories(response?.data?.categories)
		}).catch((error) => {
			console.error({ error })
		})
	}

	const getProductsById = (selectedCategoryId) => {
		getProductsByCategoryId(selectedCategoryId).then((response) => {
			setProducts(response?.data?.data)
		}).catch((error) => {
			console.error({ error })
		})
	}

	useEffect(() => {
		if (selectedCategoryId) {
			getProductsById(selectedCategoryId)
		}
	}, [selectedCategoryId])



	const showFilter = () => {
		setShowFilterSection((prev) => !prev);
	};

	const handleOrderType = (value) => {
		setOrderType(value)
	}



	const isTableLoading = isFilterPatientLoading || isLoadingPatient;
	return (
		<>
			<div className="wrapper orderWrapper">
				<div className="container-fluid">
					<div className="row mb-4">
						<div className="col-md-12">
							<div className="titleBar d-flex justify-content-between">
								<h4>Patient List</h4>
							</div>
						</div>
					</div>

					<div className="row mb-4">
						<div className="col-md-12">
							<div className="topSection titleRow">
								<div className="leftSection">
								</div>
								<div className="rightSection rightWrapper">
									<div className="formItem">
										<Input
											placeholder="Search Patient.."
											onChange={handlePatientSearch}
											className="input_patient_search"
										/>
									</div>
									<div className="formItem">
										<Select
											placeholder="Select Last Visit"
											className="formSelect w-100"
											onChange={(value) => {
												handledayChange(value);
											}}
										>
											<Option value="7">7 days</Option>
											<Option value="30">30 days</Option>
											<Option value="45">45 days</Option>
											<Option value="60">60 days</Option>
											<Option value="90">90 days</Option>
											<Option value="120">120 days</Option>
										</Select>
									</div>
									<div className="formItem">
									<Select
											placeholder="Select Last Order Days"
											className="formSelect w-100"
											disabled={last_visit_days !==null? false: true}
											value={lastOrderday}
											onChange={(value) => {
												handleLastOrderDay(value);
											}}
										>
											<Option value="7">7 days</Option>
											<Option value="30">30 days</Option>
											<Option value="45">45 days</Option>
											<Option value="60">60 days</Option>
											<Option value="90">90 days</Option>
											<Option value="120">120 days</Option>
										</Select>
									</div>
									<div className="formItem">
										<Select
											placeholder="Select Parent Category"
											style={{ width: "100%" }}
											className="formSelect"
											onClick={() => categories.length === 0 ? getCategoriesList() : <></>}
											onChange={(e) => {
												setSelectedCategoryId(e)
												setSelectedProductId(null)
											}}
											notFoundContent={isCategoriesLoading ? (
												<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
													<Spin />
												</div>
											) : null}
											options={categories?.map((option) => ({
												value: option.id,
												label: option.name,
											}))}
										/>
									</div>
									<div className="formItem">
										<Select
											placeholder="Products"
											style={{ width: "100%" }}
											className="formSelect"
											disabled={selectedCategoryId ? false : true}
											onChange={(e) => {
												const selectedProduct = products.find((product) => product.id === e);
												setSelectedProductId({
													value: selectedProduct?.id,
													item_name: selectedProduct?.item_name,
												});
											}}
											notFoundContent={isProductLoading ? (
												<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
													<Spin />
												</div>
											) : null}
											options={products?.map((option) => ({
												value: option.id,
												label: option.item_name,
											}))}
										/>
									</div>
									<div className="formItem">
										<Select
											placeholder="Select Last Visit"
											className="formSelect w-100"
											onChange={(value) => {
												handleOrderType(value);
											}}
											defaultValue="Single"
										>
											<Option value="single_order">Single</Option>
											<Option value="bulk_order">Bulk Order</Option>
										</Select>
									</div>
								</div>
							</div>
						</div>
					</div>

					{showFilterSection && (
						<div className="card filterSection">
							<div className="card-header bg-white">
								<h4 className="card-title">Filters</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-4 mb-4">
										<Input
											placeholder="Date Last Visited"
											className="formControl"
										/>
									</div>
									<div className="col-md-4 mb-4">
										<Select
											placeholder="Exclude patients..."
											className="formSelect"
											style={{ width: "100%" }}
										>
											<Option value="exclude">
												Exclude all patients who have been ordered
											</Option>
										</Select>
									</div>
									<div className="col-md-4 mb-4">
										<Select
											placeholder="Select date last ordered"
											className="formSelect"
											style={{ width: "100%" }}
										>
											<Option value="date">Select date last ordered</Option>
										</Select>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="col-md-12 mb-5">
						<div className="card tableCard">
							<div className="cartd-body p-4">
								<div className="tableWrapper">
									<Table
										className="registered-user-table"
										bordered
										columns={tableColumns}
										dataSource={dataSource}
										pagination={{
											current: patientPage,
											pageSize: patientPerPage,
											total: daysFilteredPatientData?.length > 0 ? daysFilterPatientTotal : filteredPatientTotal,
											onChange: (page) => setPatientPage(page),
											onShowSizeChange: onPageSizeChange,
											showSizeChanger: true,
											pageSizeOptions: pageSizeOptions,
										}}
										// rowSelection={rowSelection}
										loading={isTableLoading}
										showSorterTooltip={false}
										scroll={{ x: 'auto' }}
									/>
								</div>
							</div>
						</div>
					</div>
					{orderType === "bulk_order" && selectedRowKeys?.length > 0 && (
						<div className="create-order-wrapper">
							<Button className="craete-order-button">Create Order</Button>
						</div>
					)
					}
				</div>
			</div>
			<Modal
				title="Prescriber Information"
				centered
				open={modal1Open}
				onOk={() => setModal1Open(false)}
				onCancel={() => setModal1Open(false)}
				footer={false}
				width={700} // Adjust width for better visibility
				className="prescriberModal"
			>
				<Form
					name="basic"
					layout="vertical"
					initialValues={{
						drug_name: selectedProductId?.item_name,
						first_name: settingDetails?.prescriber_details?.firstName,
						last_name: settingDetails?.prescriber_details?.lastName,
						npi: settingDetails?.prescriber_details?.npi
					}}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"

				>
					{/* First and Last Name Fields */}
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								name="first_name"
								label="First Name"
								rules={[{ required: true, message: "Please enter first name." }]}
							>
								<Input disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="last_name"
								label="Last Name"
								rules={[{ required: true, message: "Please enter last name." }]}
							>
								<Input disabled />
							</Form.Item>
						</Col>
					</Row>

					{/* License State and License Number Fields */}
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								name="npi"
								label="National Provider Identifier"
								rules={[{ required: true }]}
							>
								<Input disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="drug_name"
								label="Drug Name"
								rules={[{ required: true, message: "Please enter drug name." }]}
							>
								<Input disabled />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								name="drugStrength"
								label="Drug Strength"
								rules={[{ required: true, message: "Please select drug strength." }]}
							>
								<Select
									placeholder="Select Drug Strength"
								>
									<Option value="5/5/1 mg/ml 1ml Vial">5/5/1 mg/ml 1ml Vial</Option>
									<Option value="5/5/1 mg/ml 2ml Vial">5/5/1 mg/ml 2ml Vial</Option>
									<Option value="10mg/5mg/500mcg/ml 2ml Vial">10mg/5mg/500mcg/ml 2ml Vial</Option>
									<Option value="10mg/5mg/500mcg/ml 3ml Vial">10mg/5mg/500mcg/ml 3ml Vial</Option>
									<Option value="10mg/5mg/500mcg/ml 4ml Vial">10mg/5mg/500mcg/ml 4ml Vial</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="drug_form"
								label="Drug Form"
								rules={[{ required: true, message: "Please select drug form." }]}
							>
								<Select
									placeholder="Select Drug Form"
								>
									<Option value="vials">Vials</Option>
									<Option value="doses">Doses</Option>
									<Option value="tablets">Tablets</Option>
									<Option value="capsules">Capsules</Option>
									<Option value="Inj">Injections</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>

					{/* Drug Name and Quantity Fields */}
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								name="quantity"
								label="Quantity"
								rules={[{ required: true, message: "Please enter quantity." }]}
							>
								<Input
									type="text"
									placeholder="Please enter quantity"
									rules={[
										{
											validator: (_, value) =>
												value && value.length <= 3
													? Promise.resolve()
													: Promise.reject(
														new Error("Quantity must not exceed 3 digits.")
													),
										},
									]}
									maxLength={3}
									onKeyPress={(e) => {
										if (!/[0-9]/.test(e.key)) {
											e.preventDefault();
										}
									}}
									onClick={(e) => e.stopPropagation()}
									onPaste={(e) => {
										const paste = e.clipboardData.getData("text");
										if (!/^\d{0,5}$/.test(paste)) {
											e.preventDefault();
										}
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="daysSupply"
								label="Days of Supply"
								rules={[{ required: true, message: "Please days supply." }]}
							>
								<Input
									type="text"
									placeholder="Please enter days of supply"
									rules={[
										{
											validator: (_, value) =>
												value && value.length <= 3
													? Promise.resolve()
													: Promise.reject(
														new Error("Quantity must not exceed 3 digits.")
													),
										},
									]}
									maxLength={3}
									onKeyPress={(e) => {
										if (!/[0-9]/.test(e.key)) {
											e.preventDefault();
										}
									}}
									onClick={(e) => e.stopPropagation()}
									onPaste={(e) => {
										const paste = e.clipboardData.getData("text");
										if (!/^\d{0,5}$/.test(paste)) {
											e.preventDefault();
										}
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								name="quantity_units"
								label="Quantity Units"
								rules={[{ required: true, message: "Please select quantity units" }]}
							>
								<Select
									placeholder="Select Quantity Units"
								>
									<Option value="mL">ml</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item
								name="clinicalDifferenceStatement"
								label="Clinical Difference Statement"
								rules={[{ required: true, message: "Please enter clinical difference statement." }]}
							>
								<TextArea
									rows={4}
									placeholder="Please enter clinical difference statement."
								/>
							</Form.Item>
						</Col>
					</Row>

					{/* Drug Directions Field */}
					<Row>
						<Col span={24}>
							<Form.Item
								name="directions"
								label="Drug Directions"
								rules={[{ required: true, message: "Please enter drug direction." }]}
							>
								<TextArea
									rows={4}
									placeholder="Please enter directions to take drug."
								/>
							</Form.Item>
						</Col>
					</Row>

					<div className="actionWrapper">
						{/* Submit Button */}
						<Form.Item>
							<Button disabled={prescriberLoader} icon={<MdRefresh />}
								loading={prescriberLoader} type="primary" htmlType="submit" block>
								Submit
							</Button>
						</Form.Item>
					</div>
				</Form>
			</Modal>
			<Modal
				title="Add Shipping Details"
				centered
				open={shippingModal}
				onOk={() => setShippingModal(false)}
				onCancel={() => setShippingModal(false)}
				className="prescriberModal"
				footer={false}
				width={600}
			>
				<Shipping getOrderData={getOrderData} selectedOrderData={selectedOrderData} setShippingModal={setShippingModal} />
			</Modal>

			<Modal
				title="Shipping Details"
				centered
				open={shippingDetailsModal}
				onOk={() => setShippingDetailsModal(false)}
				onCancel={() => setShippingDetailsModal(false)}
				className="prescriberModal"
				footer={false}
			>
				<ShippingDetails selectedShippingData={selectedShippingData} />
			</Modal>
		</>
	);
};

export default OrderingForMedication;
