import React from 'react';
import { Route, Routes, Navigate  } from "react-router-dom";
import { useSelector } from 'react-redux';
import Login from '../pages/auth/login';
import ForgotPassword from '../pages/auth/forgotPassword';
import Account from '../pages/account';
import SidebarLayout from '../components/Layout/layout';
import Homepage from '../pages/home';
import Category from '../pages/category';
import Inventory from '../pages/inventory';
import Register from '../pages/auth/register';
import AddProducts from '../pages/products';
import FilterProducts from '../pages/category/filterProduct';
import ProductDetails from '../pages/category/productDetails';
import ResetPassword from '../pages/auth/resetPassword';
import EditProduct from '../pages/products/editProduct';
import Patient from '../pages/patient';
import Appointment from '../pages/appointments';
import Patientappointment from '../pages/patient/patientappointment';
import Ordering from '../pages/order';
import Dashboard from '../pages/dashboard';
import Patientreengage from '../pages/patient/patientreengage';
import Audience from '../pages/audience';
import InventoryManagement from '../pages/inventory/inventoryManagement';
import Audienceuser from '../pages/audience/audienceuser';
import RecentPatients from "../pages/category/recentPatients";
import RegisteredUsers from "../pages/users/registeredUsers";
import UpdatePassword from "../pages/auth/updatePassword";
import InventoryChangeLogs from "../pages/inventory/inventoryChangeLogs"
import PendingOrders from "../pages/order/pendingOrders";
import Settings from "../pages/setting/settings";
import OrderList from '../pages/order/orderList';
const AppRoute = () => {
    const userData = useSelector((state) => state.persistedReducer);
    const isAuthenticated = userData.isAuthenticated;
    
    return (
        <Routes>
            {isAuthenticated ? (
                <>
                    <Route element={<SidebarLayout />}>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/allproducts" element={<Homepage />} />
                        <Route path="/category" element={<Category />} />
                        <Route path="/my-account" element={<Account />} />
                        <Route path="/inventory" element={<Inventory />} />
                        <Route path="/add-product" element={<AddProducts />} />
                        <Route path="/products" element={<FilterProducts />} />
                        <Route path="/product-details" element={<ProductDetails />} />
                        <Route path="/recent_patient_list" element={<RecentPatients />} />
                        <Route path="/edit-product" element={<EditProduct />} />
                        <Route path="/patients" element={<Patient />} />    
                        <Route path="/appointments" element={<Appointment />} />    
                        <Route path="/patientappointment" element={<Patientappointment />} />    
                        <Route path="/patient_list" element={<Ordering />} />   
                        <Route path="/campaign" element={<Patientreengage />} />    
                        <Route path="/audience" element={<Audience />} />    
                        <Route path="/audienceuser" element={<Audienceuser />} />    
                        <Route path="/inventorymanagement" element={<InventoryManagement />} />    
                        <Route path="/login" element={<Navigate to="/" />} />
                        <Route path="/register" element={<Navigate to="/" />} />
                        <Route path="/password-reset" element={<Navigate to="/" />} />
                        <Route path="/forget-password"element={<Navigate to="/" />} />
                        <Route path="/inventory-change-log"element={<InventoryChangeLogs />} />
                        <Route path="/order-list"element={<OrderList />} />
                        {userData?.user?.user?.user_role !== "nurse" && (
                            <>
                                <Route path="/registered_user" element={<RegisteredUsers />} />
                                <Route path="/settings" element={<Settings />} />
                                <Route path="/pending_orders" element={<PendingOrders />} />
                            </>
                        )}
                        {userData?.user?.user?.user_role === "nurse" && (
                            <>
                                <Route path="/registered_user" element={<Navigate to="/" />} />
                                <Route path="/settings" element={<Navigate to="/" />} />
                                <Route path="/pending_orders" element={<Navigate to="/" />} />
                            </>
                        )}

                    </Route>
                </>
            ) : (
                <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/password-reset" element={<ForgotPassword />} />
                    <Route path="/forget-password" element={<ResetPassword />} />
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/category" element={<Navigate to="/login" />} />
                    <Route path="/allproducts" element={<Navigate to="/login" />} />
                    <Route path="/my-account" element={<Navigate to="/login" />} />
                    <Route path="/products" element={<Navigate to="/login" />} />
                    <Route path="/product-details" element={<Navigate to="/login" />} />
                    <Route path="/recent-patient-list" element={<Navigate to="/login" />} />
                    <Route path="/inventory" element={<Navigate to="/login" />} />
                    <Route path="/add-product" element={<Navigate to="/login" />} />
                    <Route path="/edit-product" element={<Navigate to="/login" />} />
                    <Route path="/patients" element={<Navigate to="/login" />} />
                    <Route path="/appointments" element={<Navigate to="/login" />} />  
                    <Route path="/patientappointment" element={<Navigate to="/login" />} /> 
                    <Route path="/patient_list" element={<Navigate to="/login" />} />    
                    <Route path="/campaign" element={<Navigate to="/login" />} />    
                    <Route path="/audience" element={<Navigate to="/login" />} />    
                    <Route path="/audienceuser" element={<Navigate to="/login" />} />    
                    <Route path="/inventorymanagement" element={<Navigate to="/login" />} />    
                    <Route path="/registered_user"element={<Navigate to="/login" />} />
                    <Route path="/create-password"element={<UpdatePassword />} />
                    <Route path="/settings" element={<Navigate to="/login" />}/>
                    <Route path="/order-list" element={<Navigate to="/login" />}/>
                </>
            )}
        </Routes>
    );
};

export default AppRoute;
