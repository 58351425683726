import React from 'react';
import { Tabs } from "antd";
import SingleOrderList from "./singleOrderList";
import BulkOrderList from "./bulkOrderList"

export default function OrderList() {
    const items = [
        {
            key: '1',
            label: 'Single Order List',
            children: <SingleOrderList />,
        },
        {
            key: '2',
            label: 'Bulk Order List',
            children: <BulkOrderList />,
        },

    ];


    return (

        <div className="wrapper orderWrapper">
        <div className="container-fluid">
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="titleBar d-flex justify-content-between">
                        <h4>Orders List</h4>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-5">
                <div className="card tableCard">
                    <div className="card-body">
                            <Tabs
                            className="pending-order-tab"
                                defaultActiveKey="1"
                                items={items}
                            />
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
