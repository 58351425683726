import React, { useState, useEffect } from "react";
import { Table, Spin, Modal } from "antd";
import moment from 'moment';
import {
    useGetPendingOrderMutation,
    useApproveOrderMutation,
    useDenyOrderMutation
} from "../../redux/slices/productSlice";
import Swal from "sweetalert2";
import { IoMdInformationCircleOutline } from "react-icons/io";

const BulkPendingOrders = () => {
    const [getPendingOrder, { isPendingOrderLoading }] = useGetPendingOrderMutation();
    const [orderData, setorderData] = useState([]);
    const [approveOrder, { isLoading: isApproveOrderLoading }] = useApproveOrderMutation();
    const [denyOrder, { isLoading: isDenyOrderLoading }] = useDenyOrderMutation();
    const [recordId, setRecordId] = useState(null)
    const [detailModal, setDetailModal] = useState(false)
    useEffect(() => {
        getOrderData()
    }, []);



    const getOrderData = () => {
        getPendingOrder()
            .then((res) => {
                setorderData(res?.data?.data);
            })
            .catch((err) => {
                console.error("err", err);
            });
    }

    const handleOrderData = (data) => {
        setRecordId(data.id)
        approveOrder({ orderId: data.id }).then((res) => {
            Swal.fire({
                title: "Order Approved Successfully",
                icon: "success",
            });
            getPendingOrder()
                .then((res) => {
                    setorderData(res?.data?.data);
                })
                .catch((err) => {
                    console.error("err", err);
                });
        }).catch((err) => {
            console.warn({ err })
        })
        setRecordId(null)
    }

    const handleOrderDeny = (data) => {
        setRecordId(data.id)
        Swal.fire({
            title: "Add reason",
            text: "Add reason for order deny.",
            input: 'textarea',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                let formData = {
                    "order_id": data?.id,
                    "denial_reason": result?.value ? result?.value : ""
                };
                denyOrder(formData).then((response) => {
                    Swal.fire({
                        title: "Order Denied Successfully",
                        text: `${response?.data?.message}`,
                        icon: "success",
                    });
                    getOrderData().catch((err) => {
                        console.error("err", err);
                    });
                }).catch((err) => {
                    getOrderData();
                    console.warn({ err });
                });
            } else if (result.isDismissed) {
                console.log("Order denial was canceled.");
            }
        });
        setRecordId(null)
    }

    const Patientdetailscolumn = [
        // {
        //     title: "Name",
        //     dataIndex: "name",
        //     key: "name",
        //     render: ((text, record) => (
        //         <>
        //             <span>{`${record?.data?.order?.patient?.firstName} ${record?.data?.order?.patient?.lastName}`}</span>
        //         </>
        //     ))
        // },
        {
            title: "Order By",
            dataIndex: "order_by",
            key: "order_by",
            render: ((text, record) => (
                <>
                    <span>{record.order_by}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "dateLastOrdered",
            key: "dateLastOrdered",
            render: ((text, record) => (
                <>
                    <span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "dateLastOrdered",
            key: "dateLastOrdered",
            render: ((text, record) => (
                <>
                    <span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
                </>
            ))
        },
        {
            title: "Order Quantity",
            dataIndex: "orderQuantity",
            key: "orderQuantity",
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.rxs[0]?.quantity ? record?.data?.order?.rxs[0]?.quantity : "N/A"}</span>
                </>
            ))
        },
        {
            title: "View Details",
            dataIndex: "viewDetails",
            key: "viewDetails",
            render: ((text, record) => (
                <>
                   <IoMdInformationCircleOutline onClick={()=> setDetailModal(true)}  style={{ cursor: "pointer", color: '#b4a099' }}/>
                </>
            ))
        },
        // {
        //     title: "Delivery Address",
        //     dataIndex: "deliveryAddress",
        //     key: "deliveryAddress",
        //     render: ((text, record) => (
        //         <>
        //             <span>{record?.data?.order?.patient?.address1 ? record?.data?.order?.patient?.address1 : "N/A"}</span>
        //         </>
        //     ))
        // },
        {
            title: "Update Order",
            dataIndex: "update_order",
            key: "update_order",
            render: ((text, record) => {
                return (
                    <div className='action-button'>

                        {ActionLoading && recordId === record?.id ? (
                            <Spin />  // Render spinner when loading
                        ) : (
                            <>
                                <button className='approve-button' onClick={() => handleOrderData(record)}>
                                    {'Approve'}
                                </button>
                                <button className='deny-button' onClick={() => handleOrderDeny(record)}>
                                    {'Deny'}
                                </button>
                            </>
                        )}
                    </div>
                );
            })
        },
    ];

    const BulkOrderColumn = [
         {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: ((text, record) => (
                <>
                    <span>{`${record?.data?.order?.patient?.firstName} ${record?.data?.order?.patient?.lastName}`}</span>
                </>
            ))
        },
        {
            title: "Order By",
            dataIndex: "order_by",
            key: "order_by",
            render: ((text, record) => (
                <>
                    <span>{record.order_by}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "dateLastOrdered",
            key: "dateLastOrdered",
            render: ((text, record) => (
                <>
                    <span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
                </>
            ))
        },
        {
            title: "Date Last Ordered",
            dataIndex: "dateLastOrdered",
            key: "dateLastOrdered",
            render: ((text, record) => (
                <>
                    <span>{moment(record.created_at).format('MM/DD/YY hh:mm:ss A')}</span>
                </>
            ))
        },
        {
            title: "Order Quantity",
            dataIndex: "orderQuantity",
            key: "orderQuantity",
            render: ((text, record) => (
                <>
                    <span>{record?.data?.order?.rxs[0]?.quantity ? record?.data?.order?.rxs[0]?.quantity : "N/A"}</span>
                </>
            ))
        },
    ];



    const ActionLoading = isApproveOrderLoading || isDenyOrderLoading;
    return (
        <>
            <div className="wrapper orderWrapper">
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <div className="titleBar d-flex justify-content-between">
                                <h4>Bulk Pending Orders</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-5">
                        <div className="card tableCard">
                            <div className="card-body">
                                <div className="tableWrapper registered-user-table">
                                    <Table
                                        columns={Patientdetailscolumn}
                                        dataSource={orderData}
                                        pagination={true}
                                        bordered
                                        className="mb-4 customOrderTable productTable"
                                        loading={isPendingOrderLoading}
                                        scroll={{ x: 'auto' }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
				title="Order Details"
				centered
				open={detailModal}
				onOk={() => setDetailModal(false)}
				onCancel={() => setDetailModal(false)}
				className="bulk-order-modal"
				footer={false}
				width={600}
			>
                 <div className="tableWrapper registered-user-table">
				<Table
                                        columns={BulkOrderColumn}
                                        dataSource={orderData}
                                        pagination={true}
                                        bordered
                                        className="mb-4 customOrderTable productTable"
                                        loading={isPendingOrderLoading}
                                        scroll={{ x: 'auto' }}
                                    />
                                    </div>
			</Modal>
        </>
    );
};

export default BulkPendingOrders;
