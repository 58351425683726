import React, { useState, useEffect } from "react";
import { Table, Input, Select, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { MdAddBox, MdDelete, MdEdit } from "react-icons/md";
import { useGetProductMutation, useDeleteProductMutation, useGetCategoriesMutation } from "../../redux/slices/productSlice";
import debounce from '../../HelperFunctions';
import Swal from 'sweetalert2'
const Homepage = () => {
    const navigate = useNavigate();
    const [getProduct, { isLoading }] = useGetProductMutation();
    const [getCategories, { isLoading: isCategoriesLoading }] = useGetCategoriesMutation();
    const [deleteProduct] = useDeleteProductMutation();
    const [productData, setproductData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [productPerPage, setProductPerPage] = useState(10);
    const [productPage, setProductPage] = useState(1)
    const [productTotal, setProductTotal] = useState(0)
    const [categories, setCategories] = useState([])
    const [categoryId, setCategoryId] = useState(null)
    const [search, setSearch] = useState(null)
    const getProductData = async (query) => {
        try {
            const res = await getProduct(query);
            setProductTotal(res?.data?.total)
            const formattedData = res?.data?.data?.map((item) => ({
                key: item.id,
                imageUrl: item.image,
                name: item.item_name,
                vendor: item.vendor_name ? item.vendor_name : 'N/A',
                company: item.company_name ? item.company_name : "N/A",
                category: item.category.name,
                price: `$${item.price}`,
                stock: item.quantity,
                created_by:item?.craeted_by
            }));
            setproductData(formattedData);
            setFilteredData(formattedData);
        } catch (error) {
            console.error("error", error);
        }
    }
    useEffect(() => {
        let query = `?per_page=${productPerPage}&page=${productPage}`
        if (categoryId) {
            query += `&category_id=${categoryId}`
        }

        if(search){
            query += `&search=${search}`
        }
        getProductData(query);
    }, [productPerPage, productPage]);


    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(productTotal);

    const onPageSizeChange = (current, size) => {
        setProductPerPage(size);
        setProductPage(1);
    };



    const tableColumns = [
        {
            title: "Product",
            dataIndex: "product",
            key: "product",
            render: (text, record) => (
                <div className="productBox" style={{ display: "flex", alignItems: "center" }}>
                    <div className="imgBox" style={{ marginRight: "20px" }}>
                        {record?.imageUrl ? (
                            <img
                                src={record.imageUrl}
                                alt={record.name}
                                className="img-fluid"
                                style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px" }}
                            />
                        ) : (
                            <p className="prouct-image-text">Product Image</p>
                        )}

                    </div>
                    <span style={{ textTransform: 'capitalize', fontWeight: '600' }}>{record.name}</span>
                </div>
            ),
        },
        {
            title: "Company name",
            dataIndex: "company",
            key: "company",
            // sorter: (a, b) => a.company.length - b.company.length,
        },
        {
            title: "Vendor name",
            dataIndex: "vendor",
            key: "vendor",
            // sorter: (a, b) => a.vendor.length - b.vendor.length,
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            key: "created_by",
            // sorter: (a, b) => a.vendor.length - b.vendor.length,
            render: (text, record) => (
               <span>{record?.craeted_by ? record?.craeted_by : "N/A"}</span>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            // sorter: (a, b) => a.category.length - b.category.length,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            // sorter: (a, b) => a.price.length - b.price.length,
        },
        {
            title: "In Stock",
            dataIndex: "stock",
            key: "stock",
            // sorter: (a, b) => a.stock - b.stock,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="actionButton">
                    <button type="button" className="btn primaryBtn" onClick={(e) => {
                        e.stopPropagation();
                        navigate("/edit-product", {
                            state: { productId: record.key },
                        });
                    }}
                    >
                        <MdEdit style={{ cursor: "pointer", color: '#b4a099' }} />
                    </button>
                    <button type="button" className="btn primaryBtn" onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(record.key);
                    }}
                    >
                        <MdDelete
                            style={{ cursor: "pointer", color: '#ff0000' }}
                        />
                    </button>
                </div>

            ),
        },
    ];
    const handleDelete = async (id) => {
        try {
            Swal.fire({
                title: "Are you sure you want to delete this product?",
                showDenyButton: true,
                icon: "warning",
                confirmButtonText: "Yes",
                denyButtonText: "No",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await deleteProduct(id); // Await the deleteProduct function
                    const { product, message } = response.data;

                    if (product) {
                        Swal.fire({
                            title: message,
                            icon: "success",
                        });
                        let query = `?per_page=${10}&page=${1}`
            if (categoryId) {
                query += `&category_id=${categoryId}`
            }
    
            if(search){
                query += `&search=${search}`
            }
            getProductData(query);
                    } else if (response.error) {
                        Swal.fire({
                            title: response.error.data.message,
                            icon: "error",
                            confirmButtonText: 'OK',
                        });
                        return;
                    }
                } else if (result.isDenied) {
                    Swal.fire({
                        title: "Your product is safe!",
                        icon: "info",
                        confirmButtonText: "OK",
                    });
                }
            });

            
        } catch (error) {
            console.error("Error deleting product:", error);
            Swal.fire({
                title: "Error deleting product",
                icon: "error",
                confirmButtonText: 'OK',
            });
        }
    };

    const getCategoriesList = () => {
        getCategories().then((response) => {
            setCategories(response?.data?.categories)
        }).catch((error) => {
            console.error({ error })
        })
    }

    const handleAddProduct = () => {
        navigate("/add-product");
    };

    const handleRowClick = (record) => {
        navigate("/product-details", {
            state: { productId: record.key },
        });
    };

    const handleCategoryChange = (value) => {
        setCategoryId(value)
        let query = `?per_page=${10}&page=${1}&category_id=${value}`
        if(search){
            query += `&search=${search}`
        }
        getProductData(query)

    }

    const handleSearchProduct = async (event) => {
        setSearch(event?.target?.value);
        let query = `?per_page=${productPerPage}&page=${productPage}`;
        if (event?.target?.value !== '') {
            query += `&search=${event?.target?.value}`
        }
        if(categoryId){
           query += `&category_id=${categoryId}`
        }
        let res = await debounce(getProduct, query)
        console.info({res})
        setproductData(res?.data?.data)
        setFilteredData(res?.data?.data)
        setProductTotal(res?.data?.total)
    };

    return (
        <div className="wrapper productCatalogWrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Products Catalog</h4>
                            <div className="rightWrapper">
                                <Input
                                    placeholder="Search here"
                                    onChange={handleSearchProduct}
                                    className="homesearchInput titleRowInput"
                                />
                                <Select
                                    placeholder="Select Parent Category"
                                    className="formSelect category-select me-3"
                                    onClick={() => categories?.length === 0 ? getCategoriesList() : <></>}
                                    onChange={(e) => {
                                        handleCategoryChange(e)
                                    }}
                                    notFoundContent={isCategoriesLoading ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Spin />
                                        </div>
                                    ) : null}
                                    options={categories?.map((option) => ({
                                        value: option.id,
                                        label: option.name,
                                    }))}
                                />
                                <div className="buttonWrap">
                                    <button
                                        type="button"
                                        className="btn backBtn"
                                        onClick={() => handleAddProduct()}
                                    >
                                        <MdAddBox /> Add Product
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="productTable"
                                columns={tableColumns}
                                dataSource={filteredData}
                                pagination={{
                                    current: productPage,
                                    pageSize: productPerPage,
                                    total: productTotal,
                                    onChange: (page) => setProductPage(page),
                                    onShowSizeChange: onPageSizeChange,
                                    showSizeChanger: true,
                                    pageSizeOptions: pageSizeOptions,
                                }}
                                loading={isLoading}
                                onRow={(record) => ({
                                    onClick: () => handleRowClick(record),
                                })}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                                bordered
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;
